import {
  BoxProps,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { FC, useRef } from 'react'
import { MotionHStack } from '../components'
import { theme } from '../theme'
import { remToPixel } from '../utils'
import { Frame } from '..'

export const _ListOfIntroducedCompanies = () => {
  const ratio = useBreakpointValue({ base: 1, md: 2 }, 'md')!

  const SPEED = 2
  const LOGO_LIST_SPACING_REM = 3.5 + 1.5 * ratio
  const companyLogoWidthList = [6.25, 6.25, 6.25].map((w) => w * ratio)
  const companyLogoList = [
    <Image
      w={`${companyLogoWidthList[0]}rem`}
      src="/landing/Logo/Logo.svg"
      key={0}
    />,
    <Image
      w={`${companyLogoWidthList[1]}rem`}
      src="/landing/Icon/twitter.svg"
      key={1}
    />,
    <Image
      w={`${companyLogoWidthList[2]}rem`}
      src="/landing/Icon/facebook.svg"
      key={2}
    />,
    // ↑サンプルが入っています
    // 追加するときはロゴの形に応じていい感じに大きさを調節してください（companyLogoWidthListにWidthを追加してください）
  ]

  const ref = useRef<HTMLElement | SVGElement>(null)
  const length = process.browser
    ? remToPixel(
        companyLogoWidthList.reduce((a, b) => a + b, 0) +
          companyLogoWidthList.length * LOGO_LIST_SPACING_REM
      )
    : undefined

  return (
    <Flex
      flexDir="column"
      py={{ base: '3.75rem', md: '5rem' }}
      boxShadow={`0rem -0.125rem 0rem ${theme.colors.lightGray}, 0rem 0.125rem 0rem ${theme.colors.lightGray}`}
    >
      <Text fontWeight="bold" fontSize="1.5rem" alignSelf="center">
        導入企業一覧
      </Text>
      <Spacer flexBasis={{ base: '3.75rem', md: '5rem' }} />
      <MotionHStack
        ref={ref}
        spacing={`${LOGO_LIST_SPACING_REM}rem`}
        pos="relative"
        left="0rem"
        initial={{ left: '0px' }}
        // FIXME: 上手く繋げられてないので修正する
        animate={{ left: `-${length != null ? length : 0}px` }}
        transition={
          {
            repeat: 'Infinity',
            duration: length != null ? (0.02 / SPEED) * length : 0,
            ease: 'linear',
          } as any
        }
      >
        {[
          ...companyLogoList,
          ...companyLogoList,
          ...companyLogoList,
          ...companyLogoList,
        ].map((companyLogo, i) => {
          return <React.Fragment key={i}>{companyLogo}</React.Fragment>
        })}
      </MotionHStack>
    </Flex>
  )
}

export const ListOfIntroducedCompanies: FC<BoxProps> = (props) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const itemsPerRow = isMobile ? 2 : 4
  const itemWidth = 100 / itemsPerRow
  const itemMaxWidth = 17.5

  const companyLogoList = [
    '/landing/Image/Companies/1.png',
    '/landing/Image/Companies/2.png',
    '/landing/Image/Companies/3.png',
    '/landing/Image/Companies/4.png',
    '/landing/Image/Companies/5.png',
    '/landing/Image/Companies/6.png',
    '/landing/Image/Companies/7.png',
    '/landing/Image/Companies/8.png',
  ]

  const rows = Math.ceil(companyLogoList.length / itemsPerRow)
  const rowWidth = itemsPerRow * itemMaxWidth

  return (
    <Frame py={{ base: '5rem', md: '4rem' }} {...props}>
      <Flex flexDir="column">
        <VStack spacing={2.5} align="flex-start">
          <Heading id="導入企業一覧" variant="main">
            Introduction
          </Heading>
          <Text variant="mainHeadingDescription">
            AI議事録取れる君 導入事例
          </Text>
          <Text variant="mainHeadingDescription">
            （利用実績数 1,500社 突破）
          </Text>
        </VStack>
        <Spacer flexBasis={{ base: '2rem', md: '3.5rem' }} />
        <VStack>
          <Flex flexWrap="wrap" justifyContent="center">
            {Array.from({ length: rows }, (_, i) => (
              <Flex
                key={i}
                w="full"
                maxW={`${rowWidth}rem`}
                justifyContent="center"
              >
                {companyLogoList
                  .slice(i * itemsPerRow, (i + 1) * itemsPerRow)
                  .map((src, j) => (
                    <Image
                      key={j}
                      src={src}
                      w={`${itemWidth}%`}
                      maxW={`${itemMaxWidth}rem`}
                    />
                  ))}
              </Flex>
            ))}
          </Flex>
          <Text
            color="#555"
            fontSize="sm"
            w="full"
            maxW={`${rowWidth}rem`}
            textAlign="left"
          >
            ※ 2024年7月時点の利用実績数です。
          </Text>
        </VStack>
      </Flex>
    </Frame>
  )
}
